exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-actualidad-js": () => import("./../../../src/pages/actualidad.js" /* webpackChunkName: "component---src-pages-actualidad-js" */),
  "component---src-pages-articulos-corporate-governance-js": () => import("./../../../src/pages/articulos/corporate-governance.js" /* webpackChunkName: "component---src-pages-articulos-corporate-governance-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-la-firma-js": () => import("./../../../src/pages/la-firma.js" /* webpackChunkName: "component---src-pages-la-firma-js" */),
  "component---src-pages-nota-legal-js": () => import("./../../../src/pages/nota-legal.js" /* webpackChunkName: "component---src-pages-nota-legal-js" */),
  "component---src-pages-publicaciones-conflictos-de-interes-y-sociedades-en-crisis-js": () => import("./../../../src/pages/publicaciones/conflictos-de-interes-y-sociedades-en-crisis.js" /* webpackChunkName: "component---src-pages-publicaciones-conflictos-de-interes-y-sociedades-en-crisis-js" */),
  "component---src-pages-publicaciones-deberes-fiduciarios-de-los-administradores-de-las-sociedades-de-capital-js": () => import("./../../../src/pages/publicaciones/deberes-fiduciarios-de-los-administradores-de-las-sociedades-de-capital.js" /* webpackChunkName: "component---src-pages-publicaciones-deberes-fiduciarios-de-los-administradores-de-las-sociedades-de-capital-js" */),
  "component---src-templates-area-js": () => import("./../../../src/templates/area.js" /* webpackChunkName: "component---src-templates-area-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */)
}

